import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import Callout from '../../components/Callout';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import Basic from '../../examples/Accordion/Basic';
import AllCollapse from '../../examples/Accordion/AllCollapse';
import EntireHeaderClickable from '../../examples/Accordion/EntireHeaderClickable';
import CustomToggle from '../../examples/Accordion/CustomToggle.js';
import ContextAwareToggle from '../../examples/Accordion/ContextAwareToggle.js';
export const query = graphql`
  query AccordionQuery {
    Accordion: componentMetadata(displayName: { eq: "Accordion" }) {
      ...ComponentApi_metadata
    }
    AccordionToggle: componentMetadata(displayName: { eq: "AccordionToggle" }) {
      ...ComponentApi_metadata
    }
    AccordionCollapse: componentMetadata(
      displayName: { eq: "AccordionCollapse" }
    ) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "accordion"
    }}>{`Accordion`}</h1>
    <p className="lead">
  Accordions provide a way to restrict Card components to only open one at a
  time.
    </p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p>{`Accordions use Card components to provide styling of the Accordion components.
Use AccordionToggle to provide a button that switches between each AccordionCollapse component.`}</p>
    <h3 {...{
      "id": "basic-example"
    }}>{`Basic Example`}</h3>
    <ReactPlayground codeText={Basic} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "fully-collapsed-state"
    }}>{`Fully Collapsed State`}</h3>
    <p>{`If you want your Accordion to start in a fully-collapsed state, then simply don't pass in a `}<inlineCode parentName="p">{`defaultActiveKey`}</inlineCode>{` prop to `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{`.`}</p>
    <ReactPlayground codeText={AllCollapse} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "entire-header-clickable"
    }}>{`Entire Header Clickable`}</h3>
    <p>{`Each of the Card components in the Accordion can have their entire header clickable, by setting the AccordionToggle's
underlying component to be a CardHeader component.`}</p>
    <ReactPlayground codeText={EntireHeaderClickable} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "custom-toggle"
    }}>{`Custom Toggle`}</h3>
    <p>{`You can now hook into the Accordion toggle functionality via `}<inlineCode parentName="p">{`useAccordionToggle`}</inlineCode>{` to make custom toggle components.`}</p>
    <ReactPlayground codeText={CustomToggle} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "custom-toggle-with-expansion-awareness"
    }}>{`Custom Toggle with Expansion Awareness`}</h3>
    <p>{`You may wish to have different styles for the toggle if it's associated section is expanded,
this can be achieved with a custom toggle that is context aware and also takes advantage of the `}<inlineCode parentName="p">{`useAccordionToggle`}</inlineCode>{` hook.`}</p>
    <ReactPlayground codeText={ContextAwareToggle} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.Accordion} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.AccordionToggle} exportedBy={props.data.Accordion} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.AccordionCollapse} exportedBy={props.data.Accordion} mdxType="ComponentApi" />
    <h3 {...{
      "id": "useaccordiontoggle"
    }}>{`useAccordionToggle`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

const decoratedOnClick = useAccordionToggle(eventKey, onClick);
`}</code></pre>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      